.rbc-header {
    height: 3em;
    background-color: #9bcb41;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
}

.rbc-off-range {
    font-weight: bold;
    color: #9bcb41;
}

.active-filter {
    background-color: #e6e6e6;
}

.rbc-event-content:has(> .public-event) {
    background-color: #4c4c4c;
    /*font-size: large;*/
    /*padding: 8px;*/
}

.event-details ul {
    list-style: none;
}

.rbc-event {
    /*background: none !important;*/
    padding: 0;
}

.rbc-event-content {
    border-radius: 5px;
}

.rbc-event-content > span {
    display: block;
    border-radius: 5px;
    /*padding: 6px;*/
}

.rbc-event-content .-event {
    background-color: #7bdcb5;
}

.active-filter {
    background-color: #2d2d2d!important;
    color: #dedeb9!important;
}

.public-event {
    background-color: #ff69b4;
    color: white;
}

.pending.invoice-event, .completed-event, .confirmed-event {
    background-color: #b8ed30;
    color: #2d2d2d;
}

.new-enquiry-event {
    background-color: #49abdc;
}

.industry-event {
    color: white;
    background-color: #4c4c4c;
}

.provisional-event {
    background-color: #1b21f3;
    color: white;
}

.cancelled-event, .denied-event {
    background-color: #c22a37;
    color: white;
}

.rbc-day-bg {
    cursor: pointer;
}

.rbc-toolbar-label {
    font-size: 1.25rem;
    font-weight: 600;
}

.rbc-toolbar > .filters {
    border-radius: 0;
}

.react-datepicker-wrapper {
    min-width: 100%;
}
.react-datepicker__input-container {
    min-width: inherit;
}